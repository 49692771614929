// Cloudflare API 客戶端
// 這個文件提供了兩種API調用方式：
// 1. 服務器端：直接使用Cloudflare綁定
// 2. 客戶端：使用HTTP請求

import axios from 'axios';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

// 安全地獲取環境變量
const getEnvVar = (name, defaultValue) => {
  try {
    return typeof import.meta !== 'undefined' && import.meta.env && import.meta.env[name] 
      ? import.meta.env[name] 
      : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

// 安全地獲取localStorage
const getFromLocalStorage = (key, defaultValue) => {
  if (isServer) return defaultValue;
  
  try {
    return typeof localStorage !== 'undefined' ? localStorage.getItem(key) || defaultValue : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

// API 基礎 URL（客戶端使用）
// 使用API代理URL，而不是直接訪問API
const API_BASE_URL = getEnvVar('VITE_API_PROXY_URL', 'https://api-proxy.javplay.tv');

// 是否使用緩存
const USE_CACHE = getEnvVar('VITE_USE_CACHE', 'true') === 'true';

// 創建 axios 實例（客戶端使用）
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 請求攔截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 不再需要添加API令牌，由代理Worker處理
    // 獲取當前語言
    let language = 'tw'; // 默認語言
    
    // 從 URL 路徑中提取語言參數（用於 SSR/SSG）
    if (isServer && config.url && config.ssrContext && config.ssrContext.url) {
      const urlPath = config.ssrContext.url;
      const langMatch = urlPath.match(/^\/([a-z]{2})(\/|$)/);
      if (langMatch && ['tw', 'cn', 'en'].includes(langMatch[1])) {
        language = langMatch[1];
      }
    } else {
      // 客戶端從 localStorage 獲取語言
      language = getFromLocalStorage('i18nextLng', 'tw');
    }
    
    // 將標準語言代碼映射到我們的簡短代碼
    if (language.startsWith('zh-TW') || language === 'zh-Hant') {
      language = 'tw';
    } else if (language.startsWith('zh-CN') || language === 'zh-Hans' || language === 'zh') {
      language = 'cn';
    } else if (language.startsWith('en')) {
      language = 'en';
    } else if (language.startsWith('ja')) {
      language = 'ja';
    }
    
    // 添加語言參數到所有請求
    if (config.params) {
      config.params.language = language;
    } else {
      config.params = { language };
    }
    
    // 添加緩存控制參數
    if (config.params) {
      config.params.use_cache = USE_CACHE;
    } else {
      config.params = { use_cache: USE_CACHE };
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 響應攔截器
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

// 服務器端上下文
let serverContext = {
  url: '',
  env: null
};

// 為 SSR/SSG 設置上下文
export const setSSRContext = (url, env) => {
  serverContext.url = url;
  serverContext.env = env;
  
  // 同時設置axios的SSR上下文（兼容舊代碼）
  axiosInstance.defaults.ssrContext = { url };
};

// 獲取當前語言（從URL或localStorage）
const getCurrentLanguage = () => {
  let language = 'tw'; // 默認語言
  
  if (isServer && serverContext.url) {
    const urlPath = serverContext.url;
    const langMatch = urlPath.match(/^\/([a-z]{2})(\/|$)/);
    if (langMatch && ['tw', 'cn', 'en'].includes(langMatch[1])) {
      language = langMatch[1];
    }
  } else {
    language = getFromLocalStorage('i18nextLng', 'tw');
  }
  
  // 將標準語言代碼映射到我們的簡短代碼
  if (language.startsWith('zh-TW') || language === 'zh-Hant') {
    language = 'tw';
  } else if (language.startsWith('zh-CN') || language === 'zh-Hans' || language === 'zh') {
    language = 'cn';
  } else if (language.startsWith('en')) {
    language = 'en';
  } else if (language.startsWith('ja')) {
    language = 'ja';
  }
  
  return language;
};

// 創建通用參數對象
const createParams = (customParams = {}) => {
  return {
    language: getCurrentLanguage(),
    use_cache: USE_CACHE,
    ...customParams
  };
};

// 通用API調用函數
// 根據環境使用不同的方式調用API
const callApi = async (path, params = {}) => {
  // 服務器端：優先使用Cloudflare綁定，如果失敗則使用HTTP請求
  if (isServer && serverContext.env && serverContext.env.API) {
    try {
      // 構建完整的API路徑（去掉開頭的斜杠）
      const apiPath = path.startsWith('/') ? path.substring(1) : path;
      
      // 構建URL查詢參數
      const searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        searchParams.append(key, value);
      }
      
      // 構建完整的URL
      const url = `${apiPath}?${searchParams.toString()}`;
      
      try {
        // 使用Cloudflare綁定調用API
        const response = await serverContext.env.API.fetch(url);
        
        // 解析JSON響應
        const data = await response.json();
        return data;
      } catch (bindingError) {
        console.error(`Error calling API via binding, falling back to HTTP request: ${path}`, bindingError);
        
        // 如果Cloudflare綁定調用失敗，使用HTTP請求作為備用方案
        // 在sitemap生成時，我們需要一個完整的API URL
        const apiUrl = 'https://api-proxy.javplay.tv'; // 使用API代理URL
        const fullUrl = `${apiUrl}${path}`;
        
        // 使用fetch進行HTTP請求，不再需要添加API令牌
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        // 解析JSON響應
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error(`Error calling API: ${path}`, error);
      throw error;
    }
  } 
  // 客戶端：使用HTTP請求
  else {
    return axiosInstance.get(path, { params });
  }
};

// API 方法
export const cloudflareApi = {
  // 獲取影片列表
  getVideos: (params = {}) => {
    return callApi('/api/videos', createParams(params));
  },
  
  // 獲取最新影片
  getLatestVideos: (limit = 10) => {
    return callApi('/api/videos/latest', createParams({ limit }));
  },
  
  // 獲取熱門影片
  getPopularVideos: (limit = 8) => {
    return callApi('/api/videos/popular', createParams({ limit }));
  },
  
  // 獲取影片詳情
  getVideoDetail: (hid) => {
    return callApi(`/api/videos/${hid}`, createParams());
  },
  
  // 按標籤獲取影片
  getVideosByTag: (tag, params = {}) => {
    // 對標籤進行編碼
    const encodedTag = encodeURIComponent(tag);
    return callApi(`/api/videos/by-tag/${encodedTag}`, createParams(params));
  },
  
  // 按片商獲取影片
  getVideosByManufacturer: (manufacturer, params = {}) => {
    // 對片商進行編碼
    const encodedManufacturer = encodeURIComponent(manufacturer);
    return callApi(`/api/videos/by-manufacturer/${encodedManufacturer}`, createParams(params));
  },
  
  // 獲取所有標籤
  getTags: () => {
    return callApi('/api/tags', createParams());
  },
  
  // 獲取所有片商
  getManufacturers: () => {
    return callApi('/api/manufacturers', createParams());
  },
  
  // 獲取所有演員
  getActors: () => {
    return callApi('/api/actors', createParams());
  },
  
  // 按演員獲取影片
  getVideosByActor: (actor, params = {}) => {
    // 對演員進行編碼
    const encodedActor = encodeURIComponent(actor);
    return callApi(`/api/videos/by-actor/${encodedActor}`, createParams(params));
  },
  
  // 搜索影片
  searchVideos: (query, params = {}) => {
    // 確保所有語言（包括日文）都能正確編碼
    const encodedQuery = encodeURIComponent(query);
    return callApi(`/api/videos/search/${encodedQuery}`, createParams(params));
  },
  
  // 搜索番號（簡化版）
  searchByBarcode: (barcode, params = {}) => {
    // 確保所有語言（包括日文）都能正確編碼
    const encodedBarcode = encodeURIComponent(barcode);
    return callApi(`/api/videos/barcode/${encodedBarcode}`, createParams(params));
  }
};

// 導出axios實例（兼容舊代碼）
export default axiosInstance;
